import React, { useCallback } from "react";
import { Link, Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import ServiceSwagger from "./swagger";
import { capitalizeFirst } from "../../utils/utils";
import ServiceDocumentation from "./documentation";

const requestInterceptor = req => {
  req.headers.Authorization = "Bearer " + btoa("TOKEN_HERE");
  return req;
};

const services = [{
  name: "party",
  description: (
    <>
      <p>Party service exposes several operations to handle a Flora customer.</p>
      <div>Example:
        <ul>
          <li>create a new customer</li>
          <li>update a profile of a customer</li>
        </ul>
      </div>
    </>
  )
},{
  name: "policy",
  description: (
    <>
      <p>Policy service exposes several operations to handle the contract of a customer.</p>
      <div>Example:
        <ul>
          <li>calculate the effective start date</li>
          <li>generate a quote</li>
        </ul>
      </div>
    </>
  )
},{
  name: "claim",
  description: (
    <>
      <p>Claim service exposes several operations to handle the claims reported by a customer.</p>
      <div>Example:
        <ul>
          <li>First Notification of Loss</li>
          <li>Add details to a claim</li>
        </ul>
      </div>
    </>
  )
},{
  name: "billing",
  description: (
    <>
      <p>Billing service exposes several operations to handle the payment.</p>
      <div>Example:
        <ul>
          <li>Stripe souscription</li>
          <li>Paypal souscription</li>
        </ul>
      </div>
    </>
  )
},{
  name: "pricing",
  description: (
    <>
      <p>Pricing service exposes several operations to calculate a price based on criteria</p>
      <div>Example:
        <ul>
          <li>calculate the price for a quote</li>
          <li>calculate the price for a contract renewal</li>
        </ul>
      </div>
    </>
  )
},{
  name: "dms",
  description: (
    <>
      <p>DMS service exposes several operations to generate and send documents</p>
      <div>Example:
        <ul>
          <li>Generate a PDF and store it in S3</li>
          <li>Generate a PDF, an HTML body and send them by mail</li>
        </ul>
      </div>
    </>
  )
},{
  name: "salesforce",
  description: (
    <>
      <p>Salesforce service exposes several operations to interact with Salesforce</p>
      <div>Example:
        <ul>
          <li>Create an account in Salesforce with an opportuni and a property</li>
          <li>Transform an opportunity into a policy</li>
        </ul>
      </div>
    </>
  )
},{
  name: "log",
  description: (
    <>
      <p>Salesforce service exposes operations to centralize logs in Elastisearch</p>
      <div>Example:
        <ul>
          <li>Create a log from Salesforce</li>
          <li>Create a log from the mobile app</li>
        </ul>
      </div>
    </>
  )
}];

export default () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
      <Route exact path={`${match.path}/documentation/:service`}>
          <ServiceDocumentation />
        </Route>        
        <Route exact path={`${match.path}/swagger/:service`}>
          <ServiceSwagger />
        </Route>
        <Route>
          <div style={{padding: "10px 50px"}}>
            <div className="page-header">
              <h1 id="containers">Services</h1>
              <p className="lead">Flora is built on a micro service platform containing the following service:</p>
            </div>
            <div className="row" style={{marginTop: "15px"}}>
              {services.map(service => (
                <Card key={service.name} service={service.name} description={service.description} />
              ))}
            </div>
          </div>
        </Route>
      </Switch>
    </>
  );
};

const Card = ({service, description}) => {
  return (
    <div className="col-md-4" style={{marginBottom: "20px"}}>
      <div className="card bg-light mb-3">
        <div className="card-body">
          <h4 className="card-title">{capitalizeFirst(service)}</h4>
          <div className="card-text">{description}</div>
          <Link className="card-link" to={`/services/swagger/${service}`}>Swagger</Link>
          <Link className="card-link" to={`/services/documentation/${service}`}>Documentation</Link>
        </div>
      </div>
    </div>
  )
}

const Select = ({services, onSelect}) => {
  return (
    <select onChange={onSelect}>
      {services.map(service => (
        <option key={service} value={service}>{service}</option>
      ))}
    </select>
  )
}

