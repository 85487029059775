import React, { useCallback, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useParams
} from "react-router-dom";
import { useSettings, useReduxSelector, useGet, useSecureGet } from "onekijs";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export default () => {
  const params = useParams();
  const settings = useSettings();
  const history = useHistory();
  const token = useReduxSelector("auth.token.access_token");

  const requestInterceptor = useCallback(
    req => {
      req.headers.Authorization = "Bearer " + token;
      req.headers["x-api-key"] = settings.api.apiKey;
      return req;
    },
    [token, settings.api.apiKey]
  );

  const onSelect = useCallback(
    e => {
      const service = e.target.value;
      history.push(`/services/swagger/${service}`);
    },
    [history]
  );

  const url = `${settings.api.baseUrl}/doc/v1/swaggers/${params.service}`;
  console.log("url", url);
  return (
    <>
      <div
        className="swagger-ui"
        style={{ textAlign: "right", paddingRight: "20px" }}
      >
        <span style={{ marginRight: "5px" }}>
          <b>Service:</b>
        </span>
        <Select
          services={["billing", "claim", "dms", "party", "policy", "pricing", "salesforce"]}
          onSelect={onSelect}
          currentService={params.service}
        />
      </div>
      <SwaggerViewer swaggerUrl={url} />

      <SwaggerUI
        url={url}
        docExpansion="list"
        requestInterceptor={requestInterceptor}
        defaultModelExpandDepth={-1}
      />
    </>
  );
};

const Select = ({ services, onSelect, currentService }) => {
  return (
    <select onChange={onSelect} style={{ width: "200px", height: "40px" }}>
      {services.map(service => (
        <option
          key={service}
          value={service}
          selected={currentService === service}
        >
          {service}
        </option>
      ))}
    </select>
  );
};

const SwaggerViewer = ({ swaggerUrl }) => {
  const [swagger, loading] = useSecureGet(swaggerUrl);
  const [show, setShow] = useState(false);

  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  return (
    <div>
      <div className="swagger-ui" style={{ margin: "-40px 0px 0px 20px" }}>
        <p style={{ marginTop: "5px", marginBottom: "0px" }}>
          <button className="btn btn-primary" onClick={toggleShow}>
            <span>View Swagger</span>
            <div
              className="arrow-down"
              style={{ margin: "0px 0px 2px 5px", display: "inline-block" }}
            ></div>
          </button>
        </p>
      </div>
      {show && (
        <div style={{ padding: "0px 10px", textAlign: "left" }}>
          <div
            style={{
              padding: "5px",
              fontSize: "0.6rem",
              height: "400px",
              margin: "20px 10px 0px 10px"
            }}
          >
            <textarea style={{backgroundColor: "#EEE", border: '0px', width: '100%', height: '400px'}}>
              {JSON.stringify(swagger, null, 2)}
            </textarea>
          </div>
        </div>
      )}
    </div>
  );
};
