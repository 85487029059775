const settings = [];

const commonSettings = {

};
settings.push(commonSettings);

// DEV specific settings
if (process.env.REACT_APP_ENV  === "local" ||process.env.REACT_APP_ENV === "dev") {
  const overrideSettings = {
    api: {
      baseUrl: "https://api.dev.flora.insure",
      apiKey: "PH2SW0WPOR6R60XoqPz7A2COwNNy2lP6nA8ERFx9"
    },
    idp: {
      cognito: {
        // MANDATORY
        type: 'oidc',                     
        authorizeUrl: 'https://login.dev.flora.insure/oauth2/authorize',    // a redirect is done to this relative URL, a absolute URL or a function returning a URL ((idp, context) => 'https://example.com/oauth2/authorize?.....'). If it's a relative URL, it's prefixed by the server.baseUrl 
        logoutUrl: 'https://login.dev.flora.insure/logout',                 // a redirect is done to this relative URL, a absolute URL or a function returning a URL ((idp, context) => 'https://example.com/oauth2/logout?.....'). If it's a relative URL, it's prefixed by the server.baseUrl 
        clientId: '16ljllh0g3meh778lnkdrbaebc',                                                          // id given by the IDP
        
        // RECOMMENDED
        tokenFetch: 'https://login.dev.flora.insure/oauth2/token',                 // mandatory if responseType is code (which is the default). Enter a relative URL, a absolute URL or a function doing the POST to the token endpoint and returning the token ((grant_type, idp, context) => return token). If it's a relative URL, it's prefixed by the server.baseUrl
        pubKeyFetch: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_HvxAH2tVO/.well-known/jwks.json',   // mandatory if validate is true. A URL returning a JWKS  or a function returning a public key (token, idp, context) => 'my_pub_key'
  
        // OPTIONAL 
        validate: true,                                      // validate the id_token and the access_token. Defaults to true. Recommended but you must then configure a pubKeyFetch
        clientSecret: null,                                   // rarely used for a javascript application
        clientAuth: 'basic',                                  // can be basic or body. If client secret is specified, it can be send via a basic authorization header or in the body of the request. (defaults to basic)
        clockSkew: 60,                                        // when a refresh token is provided by the IDP, the amount of time before the expiration of the access token to trigger the refresh. Defaults to 60 seconds
        postLoginRedirectKey: 'redirect_uri',                 // the key used by the external provider to know which is the callback URL after a successfull login (defaults to redirect_uri which is the oidc standard)
        postLogoutRedirectKey: 'logout_uri',                  // the key used by the external provider to know which is the callback URL after a successfull logout (defaults to post_logout_redirect_uri which is the oidc standard)
        securityContextFetch: 'token://id_token',             // can be token://<token_prop> or an URL or a function (idp, context) => { return {email: 'foo@example.com', roles: ['ADMIN']}}) returning the securityContext (can be a Promise). If not set, defaults to token://id_token if response_type contains id_token or token://access_token if repsonse_type contains only token
        scope: 'openid+email+profile',                  // which info should be added to the token (IDP specific setting). Defaults to "openid"
        responseType: 'code',                                 // can be 'code' (for authorization code) or 'id_token token' or 'token'. Defaults to code (you must defined a tokenFetch if responseType is code)
        pkce: false,                                           // use the PKCE extension of OIDC (only if responseType is code). Recommended. Defaults to true
        nonce: true,                                          // validate the nonce in the id_token. Mandatory if response type is not code and recommended for response type = code. Defaults to true
        state: true,                                          // recommended to mitigate attacks. Defaults to true
        callback: null,                                       // a callback method parsing the callback URL returning a token and optionally a securityContext (by default, the securityContext is retrieved from the securityContextUri defined above)
                                                              // If responseType is code, the signature is (result, idp, context) => [token, securityContext]
                                                              // If responseType contains token, the signature is (location, idp, context) => [token, securityContext]
        persist: 'localStorage',                              // valid values are localStorage (user stays logged in as long as the refresh token is valid even if the browser is closed), sessionStorage (user is logged out if the tab is closed), cookie (TTL is defined in cookieTTL)). Defaults to localStorage
        cookieTTL: null,                                      // value in seconds. If null or 0, the cookie is erased when the browser is closed.   
        pubKeyAlgorithm: 'RS256'                              // pubKey algorithm for token validation. Only used if not included in the header of the JWT. Defaults to RS256  
      }
    }   
  };
  settings.push(overrideSettings);
}

// TST specific settings
if (process.env.REACT_APP_ENV === "tst") {
  const overrideSettings = {
    api: {
      baseUrl: "https://api.tst.flora.insure"
    }
  };
  settings.push(overrideSettings);
}

// ACC specific settings
if (process.env.REACT_APP_ENV === "acc") {
  const overrideSettings = {
    api: {
      baseUrl: "https://api.acc.flora.insure"
    }
  };
  settings.push(overrideSettings);
}

// PRD specific settings
if (process.env.REACT_APP_ENV === "prd") {
  const overrideSettings = {
    api: {
      baseUrl: "https://api.flora.insure"
    }
  };
  settings.push(overrideSettings);
}

export default settings;
